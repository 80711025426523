$(function() {
	// -------------------------------------------
	// 売買LocalStorage関連 ※2023/06現在、以下で使用
	//   ・PC/SMPマイページ配下(Spring)
	//   ・PC/SMP不動産屋検索配下(Spring)
	//   ・PC/SMPマンションページ配下(Spring)
	//   ・PC/SMP不動産トップ(CMS)
	//   ・PC/SMP売買配下(CMS,Proxy)
	// -------------------------------------------
	if (!window.Nifty || !window.Nifty.Utils || !Nifty.Utils.Storage) {
		return;
	}
	window.Nifty.Buy = window.Nifty.Buy || {};

	/**
	 * 新LocalStorage設定
	 * [使用例]
	 * 取得: Nifty.Buy.Storage.getKeyValue('testKey');
	 * 保存: Nifty.Buy.Storage.setKeyValue('testKey', 'testValue');
	 * 削除: Nifty.Buy.Storage.removeKeyValue('testKey')
	 */
	Nifty.Buy.Storage = Object.create(Nifty.Utils.Storage);
	Object.assign(Nifty.Buy.Storage, {
		_date_format: 'yyyy/MM/dd hh:mm:ss'

		,Keys: {
			BUKKEN_HISTORY: 'bukken-history'
			,BUKKEN_FAVORITE: 'bukken-favorite'
			,SEARCH_HISTORY: 'search-history'
			,SEARCH_FAVORITE: 'search-favorite'  // LocalStorage→API_DBへ移行するが、便宜上定義は残す
		}
		,Api: {
			storageDataBukken: function(ids) {
				return '/buy/api/storage/bukken?id=' + ids.join(",");
			}
			,storageDataSearchCondition: function(urls) {
				var queries = [];
				for (var idx = 0, max = urls.length; idx < max; idx++) {
					queries.push('url=' + encodeURIComponent(urls[idx]))
				}
				return '/buy/api/storage/search-condition?' + queries.join('&');
			}
		}

		// 表示用日付文字列
		,formatDate: function(dateString) {
			return (dateString) ? dateString.replace(/^([0-9]+)\/([0-9]+)\/([0-9]+).+$/g, '$1年$2月$3日') : '';
		}
		// 表示用検索条件生成
		,makeSearchConditionText: function(storageItem) {
			if (!storageItem) {
				return '';
			}
			var display = (storageItem.search_condition_display) ? storageItem.search_condition_display : {};
			var types = (display.type) ? display.type.split(',') : '';
			var areas = (display.area) ? display.area.split(',') : '';
			var conds = Nifty.Utils.Array.removeEmpty([ display.cost, display.walk, display.layout, display.building_age, display.exclusive_area, display.land_area, display.features, display.keyword ]).join(',').split(',');
			var texts = [];

			var typeText = '';
			if (types.length == 1) {
				typeText = types[0];
			} else if (types.indexOf('新築マンション') >= 0 && types.indexOf('中古マンション') >= 0) {
				typeText = 'マンション一括';
			} else if (types.indexOf('新築一戸建て') >= 0 && types.indexOf('中古一戸建て') >= 0) {
				typeText = '一戸建て一括';
			} else if (types.indexOf('新築一戸建て') >= 0 && types.indexOf('新築一戸建て') >= 0) {
				typeText = '新築住宅・新築物件';
			} else if (types.indexOf('中古一戸建て') >= 0 && types.indexOf('中古一戸建て') >= 0) {
				typeText = '中古住宅・中古物件';
			}
			if (typeText) {
				typeText = '[' + typeText + ']';
			}
			texts.push(areas.slice(0, 2) + (areas.length > 2 ? 'など' : ''));
			texts.push(conds.slice(0, 4) + (conds.length > 4 ? 'など' : ''));

			var conditionText = typeText + Nifty.Utils.Array.removeEmpty(texts).join('/');
			return (conditionText) ? conditionText : '条件指定無し';
		}
		// 検索条件: 履歴/お気に入り追加
		,addSearchConditionItem: function(key, item) {
			item.date = Nifty.Utils.Date.format(new Date(), this._date_format);
			this.addItem(key, item);
		}
		// 物件: 履歴/お気に入り追加
		,addBukkenItem: function(key, item) {
			item.date = Nifty.Utils.Date.format(new Date(), this._date_format);
			this.addItem(key, item);
		}
		// 物件: お気に入り登録
		,keepBukkenItem: function(item, isAdd) {
			if (isAdd) {
				item.keepLevel = 1;
				item.date = Nifty.Utils.Date.format(new Date(), this._date_format);
				this.addItem(this.Keys.BUKKEN_FAVORITE, item);
			} else {
				this.removeItem(this.Keys.BUKKEN_FAVORITE, item.id);
			}
		}
		// 物件: お気に入り度設定
		,setBukkenKeepLevel: function(item, level) {
			item.keepLevel = parseInt(level);
			this.updateItem(this.Keys.BUKKEN_FAVORITE, item);
		}
		// 物件: 掲載期限までの日数計算
		,calcBukkenExpireDays: function(item) {
			if (item.expireDate) {
				var dateValues = item.expireDate.replace(/^([0-9]{4})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})$/g, '$1,$2,$3,$4,$5,$6').split(',');
				if (dateValues.length == 6) {
					var expireDateObj = new Date(parseInt(dateValues[0]), parseInt(dateValues[1]) - 1, parseInt(dateValues[2]), parseInt(dateValues[3]), parseInt(dateValues[4]), parseInt(dateValues[5]));
					return Math.ceil((expireDateObj.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
				}
			}
			return -1;
		}
		// 物件: 掲載期限を過ぎた物件、重複物件を削除
		,removeExpiredBukkenItems: function(key) {
			var items = Nifty.Buy.Storage.getItems(key);
			var existsBukkenIdMap = {};
			for (var idx = 0, max = items.length; idx < max; idx++) {
				var item = items[idx];
				if ((item.expireDate && this.calcBukkenExpireDays(item) < 0) || existsBukkenIdMap[item.id]) {
					this.removeItem(key, item.id);
				} else {
					existsBukkenIdMap[item.id] = true;
				}
			}
		}
		// 物件: 物件情報を更新
		,updateBukkenItems: function(key) {
			var items = this.getItems(key);
			if (Nifty.Data && Object.keys(Nifty.Data.Bukken).length >= items.length) {
				for (var idx = 0, max = items.length; idx < max; idx++) {
					var item = items[idx];
					if (item.id && Nifty.Data.Bukken[item.id]) {
						Object.assign(item, Nifty.Data.Bukken[item.id]);
					}
				}
				this.setItems(key, items);
			}
		}
	});
	var storageVersion = '2021-11-15';
	var storageConf = {};
	storageConf[Nifty.Buy.Storage.Keys.BUKKEN_HISTORY]  = 100;  // 物件閲覧履歴
	storageConf[Nifty.Buy.Storage.Keys.BUKKEN_FAVORITE] = 100;  // 物件お気に入り
	storageConf[Nifty.Buy.Storage.Keys.SEARCH_HISTORY]  =   5;  // 検索履歴
	storageConf[Nifty.Buy.Storage.Keys.SEARCH_FAVORITE] =   0;  // 保存した条件
	Nifty.Buy.Storage.init('myhome.nifty.com:buy', storageVersion, storageConf);
	if (Nifty.Buy.Storage.getVersion() < storageVersion) {
		// データ形式が古い場合
		// Nifty.Buy.Storage.clear();
	}

	// 掲載期限を過ぎた履歴/お気に入りの物件を削除
	Nifty.Buy.Storage.removeExpiredBukkenItems(Nifty.Buy.Storage.Keys.BUKKEN_HISTORY);
	Nifty.Buy.Storage.removeExpiredBukkenItems(Nifty.Buy.Storage.Keys.BUKKEN_FAVORITE);
});